.lupa-icon {
  height: 18px;
  width: 18px;
}

.border-color {
  border: solid 1px #9DBCF9;
}

.border-radius {
  border-radius: 8px !important;
}

.border-radius-left {
  border-radius: 8px 0px 0px 8px !important;
}

.border-radius-right {
  border-radius: 0px 8px 8px 0px !important;
}