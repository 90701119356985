.number-input {
  border: 1px solid #9dbcf9 !important;
  padding: 4px;
  text-align: center;
  max-width: 4rem;
  color: #4d4d4d !important;
}

.sign-button {
  border: 1px solid #9dbcf9 !important;
  color: #000ece !important;
  font-size: 1rem !important;
  width: 2rem !important;
  padding: 0 !important;
}

.sign-button:hover,
.sign-button:focus {
  background: transparent !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
