table {
  table-layout: auto;
}

.bordered {
  border: 1px solid #9dbcf9 !important;
}

.alert-container {
  position: relative;
}

.alert {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -65%);
  position: absolute !important;
  z-index: 101;
}

.btn-disabled {
  cursor: default !important;
}

.border-bottom-blue {
  border-bottom: 1px solid #9DBCF9;
}